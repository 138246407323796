import React from 'react'
import styled from 'src/styles'
import Layout from 'src/components/Layout'
import { useSiteMetadata } from 'src/hooks'
import { Inner } from 'src/components/layouts/Inner'
import PageMeta from 'src/components/PageMeta'

const NotFoundPage: React.FC = () => {
  const { siteTitle } = useSiteMetadata()
  return (
    <Layout>
      <PageMeta title={`404 | ${siteTitle}`} />
      <Inner>
        <Sc404>
          <ScHeading>Page Not Found</ScHeading>
          <p>ページが見つかりませんでした。</p>
        </Sc404>
      </Inner>
    </Layout>
  )
}
export default NotFoundPage

const Sc404 = styled.div`
  text-align: center;
  &::before {
    content: '404';
    line-height: 1;
    font-family: ${({ theme }) => theme.fontFamily.headingEn};
    color: #c9c9c9;
    font-size: 5rem;
    font-weight: bold;
  }
`

const ScHeading = styled.h1`
  font-weight: bold;
  margin-bottom: 1rem;
`
